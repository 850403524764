export default {
  proxy_path: "https://qhptjcpnsk.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://peeuw5qf1d.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyCYOofv7ePg35vhHG6jVcyRqHmVvUPXRLU",
  main_company_term_id: "2277",
  crm_base_url: "https://ccm.newcrest.forwoodsafety.com",
  forwood_id_url: "https://id.newcrest.forwoodsafety.com?redirect_uri=https://mapreport.newcrest.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.newcrest.forwoodsafety.com",
  Auth: {
    userPoolId: "ap-southeast-2_2hHiDIs9H",
    userPoolWebClientId: "7lcjvbsnl401d4js1oh7dmfnbp",
    cookieStorage: {
      domain: ".newcrest.forwoodsafety.com",
      secure: true
    }
  }
};
